import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PoliciesService } from './policies.service';

@Injectable()
export class PolicyResolver implements Resolve<any> {
  constructor(private policiesService: PoliciesService ) { }
  
  resolve(route: ActivatedRouteSnapshot): any {
    return this.policiesService.getPolicy(route.params.policyId);
  }
}
