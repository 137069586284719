import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BusinessNetworksService {
  url: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/business-networks`;
  }

  getFilters(): Observable<any> {
    return this.http.get<any>(`${this.url}/filters`, this.apiService.requestOptions());
  }

  createNetwork(params: any): Observable<any> {
    return this.http.post<any>(`${this.url}`, params, this.apiService.requestOptions());
  }

  getNetworks(params: any): Observable<any> {
    const body: any = {};
    body.page = params.page ? params.page : 1;
    body.pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    if (params.keyword) body.keyword = params.keyword;
    if (params.businessNetworkTypeId) body.businessNetworkTypeId = params.businessNetworkTypeId;
    if (params.planId) body.planId = params.planId;

    return this.http.get(this.url, this.apiService.requestOptions(body));
  }

  getNetwork(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deleteNetwork(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.apiService.requestOptions());
  }
}
