import { Observable } from 'rxjs';
 import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BusinessNetworkTypesService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/business-network-types`;
              }

  getNetworkTypes(): Observable<any> {
    return this.http.get(`${this.url}/list/ids`, this.apiService.requestOptions());
  }
}
