import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { CampaignsService } from './campaigns.service';

@Injectable()
export class CampaignResolver implements Resolve<any> {
  constructor(private campaignsService: CampaignsService ) { }
  
  resolve(route: ActivatedRouteSnapshot): any {
    return this.campaignsService.getCampaign(route.params.campaignId);
  }
}
