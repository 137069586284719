import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PoliciesService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/insurance-policies`;
              }

  updatePolicy(model: any): Observable<any> {
    return this.http.put<any>(`${this.url}/${model.insurancePolicyId}`, model, this.apiService.requestOptions());
  }

  getPolicies(params: any, filter?: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    const path = `${this.url}?page=${page}&pageSize=${pageSize}${params.keyword ? '&keyword=' + params.keyword : ''}`;
    const status = `${params.policyStatus ? '&policyStatus=' + params.policyStatus : ''}`;
    let _filter = '';
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          _filter = _filter.concat(`&${key}=true`);
        }
      });
    }
    return this.http.get(`${path}${status}${_filter}`, this.apiService.requestOptions());
  }

  getPolicy(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deletePolicy(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.apiService.requestOptions());
  }

}
