import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BenefitMovementsService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/benefit-movements`;
              }

  getMovements(page: number, filters: any): Observable<any> {
    let _filters = '';
    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          _filters = _filters.concat(`&${key}=${filters[key]}`);
        }
      });
    }
    return this.http.get(`${this.url}?page=${page}&pageSize=${environment.defaultPageSize}${_filters}`, this.apiService.requestOptions());
  }

  getMovementFilters(): Observable<any> {
    return this.http.get(`${this.url}/filters`, this.apiService.requestOptions());
  }

  updateMovementStatus(movementId: number, statusId: any): Observable<any> {
    return this.http.post(`${this.url}/${movementId}/${statusId}`, {}, this.apiService.requestOptions());
  }

}
