import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class InformativesService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/informative`;
              }

  createInformative(params: any): Observable<any> {
    return this.http.post<any>(`${this.url}`, params, this.apiService.requestOptions());
  }

  getInformatives(params: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    const path = `${this.url}?page=${page}&pageSize=${pageSize}${params.keyword ? '&keyword=' + params.keyword : ''}`;
    return this.http.get(`${path}${params.informativeStatus ? '&informativeStatus=' + params.informativeStatus : ''}`, this.apiService.requestOptions());
  }

  getInformative(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deleteInformative(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  getCampaignTracking(id: number, filter?: any): Observable<any> {
    let _filter = '?';
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          _filter = _filter.concat(`${key}=${filter[key]}&`);
        }
      });
    }
    return this.http.get(`${this.url}/${id}/tracking${_filter}`, this.apiService.requestOptions());
  }

  createAuthor(value: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v1/internal/authors`, value, this.apiService.requestOptions());
  }

}
