import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PedometersService {
  url: string;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal`;
  }

  createPedometerEvent(params: any): Observable<any> {
    return this.http.post<any>(
      `${this.url}/pedometer-events`,
      params,
      this.apiService.requestOptions()
    );
  }

  getPedometerEvents(params: any, filter?: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    let _filter = '';
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          _filter = _filter.concat(`&${key}=true`);
        }
      });
    }
    const path = `?page=${page}&pageSize=${pageSize}${
      params.keyword ? '&keyword=' + params.keyword : ''
    }${_filter}`;
    return this.http.get(`${this.url}/pedometer-events${path}`, this.apiService.requestOptions());
  }

  getPedometerEvent(id: number): Observable<any> {
    return this.http.get(`${this.url}/pedometer-events/${id}`, this.apiService.requestOptions());
  }

  deletePedometerEvent(id: number): Observable<any> {
    return this.http.delete(`${this.url}/pedometer-events/${id}`, this.apiService.requestOptions());
  }

  createEventGroup(params: any): Observable<any> {
    return this.http.post<any>(
      `${this.url}/pedometer-event-groups`,
      params,
      this.apiService.requestOptions()
    );
  }

  getEventGroups(eventId: number, params: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    const path = `?pedometerEventId=${eventId}&page=${page}&pageSize=${pageSize}${
      params.keyword ? '&keyword=' + params.keyword : ''
    }`;
    return this.http.get(
      `${this.url}/pedometer-event-groups${path}`,
      this.apiService.requestOptions()
    );
  }

  getEventTracking(id: number, filter?: any): Observable<any> {
    let _filter = '';
    if (filter) {
      _filter = '?';
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          _filter = _filter.concat(`${key}=${filter[key]}&`);
        }
      });
    }
    return this.http.get(`${this.url}/pedometer-events/${id}/tracking${_filter}`, this.apiService.requestOptions());
  }

  getEventRanking(eventId: number): Observable<any> {
    return this.http.get(`${this.url}/pedometer-events/${eventId}/ranking`, this.apiService.requestOptions());
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.http.delete(`${this.url}/pedometer-event-groups/${groupId}`, this.apiService.requestOptions());
  }

  syncPedometer(days: number): Observable<any> {
    return this.http.post(`${this.url}/pedometer-events/sync-steps?days=${days}`, {}, this.apiService.requestOptions());
  }

  downloadFullReport(): Observable<any> {
    return this.http.post(`${this.url}/pedometer-events/full-report`, {}, this.apiService.requestBlobOptions());
  }

  downloadManulStepsUploadSheet(): Observable<any> {
    return this.http.get(
      `${this.url}/pedometer-events/upload-manual-steps/sheet`,
      this.apiService.requestBlobOptions()
    );
  }
}
