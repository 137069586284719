import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { PedometersService } from './pedometers.service';

@Injectable()
export class PedometerRankingResolver implements Resolve<any> {
  constructor(private pedometersService: PedometersService ) { }
  
  resolve(route: ActivatedRouteSnapshot): any {
    return this.pedometersService.getEventRanking(route.params.eventId);
  }
}
