import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UsersService } from './users.service';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(private usersService: UsersService) { }

  resolve(route: ActivatedRouteSnapshot): any {
    return this.usersService.getUser(route.params.authId);
  }
}
