import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CompaniesService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/companies`;
              }

  createCompany(params: any): Observable<any> {
    return this.http.post<any>(`${this.url}`, params, this.apiService.requestOptions());
  }

  getCompanies(params: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    const path = `${this.url}?page=${page}&pageSize=${pageSize}${params.keyword ? '&keyword=' + params.keyword : ''}`;
    return this.http.get(path, this.apiService.requestOptions());
  }

  listCompanies(): Observable<any> {
    return this.http.get(`${this.url}/list/ids`, this.apiService.requestOptions());
  }

  getCompany(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.apiService.requestOptions());
  }

}
