import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ReportsService {
  url: string;

  constructor(private http: HttpClient,
              private apiService: ApiService) {
    this.url = `${environment.apiUrl}/v1/internal/reports`;
              }

  createReport(params: any): Observable<any> {
    return this.http.post<any>(`${this.url}`, params, this.apiService.requestOptions());
  }

  getReports(params: any, filter?: any): Observable<any> {
    const page = params.page ? params.page : 1;
    const pageSize = params.pageSize ? params.pagSize : environment.defaultPageSize;
    const path = `${this.url}?page=${page}&pageSize=${pageSize}`;
    const filters = `${params.keyword ? '&keyword=' + params.keyword : ''}${params.company ? '&companyId=' + params.company : ''}${params.category ? '&category=' + params.category : ''}`;
    return this.http.get(`${path}${filters}`, this.apiService.requestOptions());
  }

  getReport(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  deleteReport(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.apiService.requestOptions());
  }

  listCategories(): Observable<any> {
    return this.http.get(`${this.url}/categories`, this.apiService.requestOptions());
  }

}
