import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReportsService } from './reports.service';

@Injectable()
export class ReportResolver implements Resolve<any> {
  constructor(private reportsService: ReportsService ) { }
  
  resolve(route: ActivatedRouteSnapshot): any {
    return this.reportsService.getReport(route.params.reportId);
  }
}
