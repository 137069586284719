import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { InformativesService } from './informatives.service';

@Injectable()
export class InformativeResolver implements Resolve<any> {
  constructor(private informativesService: InformativesService ) { }
  
  resolve(route: ActivatedRouteSnapshot): any {
    return this.informativesService.getInformative(route.params.informativeId);
  }
}
